<template>
  <question-census
      :columns="columns"
      table-url="/xapi/question.other/listInfo"
      census-url="/xapi/question.other/censusInfo"
  >
    <template slot="action" slot-scope="scope">
      <slot name="action" v-bind="scope">
        <el-button :disabled="!$isPowers('examEdit')" @click="$handleRoute({id:scope.row.other_id,log_id:scope.row.log_id},'matchTiDanRecord')" type="text" size="small">详情</el-button>
      </slot>
    </template>
  </question-census>
</template>

<script>
import QuestionCensus from "@/views/api/teaching/question/census";
export default {
  components: {QuestionCensus},
  data(){
    return {
      columns:[
        {prop:'log_id',label:'ID',width:'80' , align:'center'},
        {prop:'title',label:'<span><font style="color:red;">类型</font> - <font style="color:green;">创建人</font> - 题目名称</span>',template:(scope)=>{
            return '<font style="color:red;">'+(scope.row.matchStr || '')+'</font> - <font style="color:green;">'+(scope.row.create_user_name || '')+'</font> - '+this.$jquery("<font>"+scope.row.other_title+"</font>").text();
          },minWidth:'320px' , align:'center'},
        {prop:'member_name',label:'用户',width:'140' , align:'center'},
        {prop:'grade',label:'得分',width:'80' , align:'center'},
        {prop:'total_grade',label:'总分',width:'80' , align:'center'},
        {prop:'answer_time',label:'答题时间',width:'80' , align:'center'},
        {prop:'last_commit_date',label:'提交时间',width:'160' , align:'center'},
        {fixed:'right',label:'操作',width:'80' , slot:'action' , align:'center'},
      ],
    }
  },
}
</script>

<style scoped>

</style>